<script>
import AuthService from "@/api/services/auth.service";
import CompanyService from "@/api/services/company.service.js";
// import QualityScoresService from "@/api/services/qualitySocreInfo.service";
import UploadService from "@/api/services/upload.service";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import AccessLevels from "@/shared/enums/aceessLevels";
import { EventBus } from "@/shared/util/event-bus.js";
import UploadDatafileConfirmationModal from "./datafile-upload-confirmation-modal";
import NotEnoughCreditsToUploadModal from "./not-enough-credits-to-upload-modal";
/**
 * Upload Dataset step 3
 */
export default {
  page: {
    title: "Upload Datafile",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    UploadDatafileConfirmationModal,
    NotEnoughCreditsToUploadModal
  },
  data() {
    return {
      creditAmountReturn: {
        OES: null,
        TS: null,
        PQ: null,
        IBS: null,
        difference: null,
        initialized: false
      },
      isExpanded: true,
      /******* Data Varibales *******/
      selectedColumns: [],
      datafileInputObject: null,
      loggedInUser: null,
      isCreditsLoading: true,
      selectedQualityScoreCounts: {
        IS: 0,
        PS: 0,
        SDS: 0,
        TS: 0,
        IBS: 0,
        CS: 0,
        OES: 0
      },
      numberOfQualityScores: 0,
      selectedIBSCount: 0,
      numberOfDatapoints: 0,
      itembatteryIndexForIndentifer: 0,
      /******* Oparational Variables ******/
      showUploadConfirmationModal: false,
      showNotEnoughCreditsModal: false,
      enableProgressBar: false
    };
  },
  created() {
    //check whatever settings saved on navbar on page load
    const navBarSetting = window.localStorage.getItem("navBarSetting");
    if (navBarSetting) this.isExpanded = navBarSetting == "expandNav";

    EventBus.$on("sidebar-toggle-action", async () => {
      this.isExpanded = !this.isExpanded;
    });
  },
  async mounted() {
    // get Logged In User
    await this.getLoggedUser();
    if (this.loggedInUser.userPreferences?.isDatafileUploading) {
      this.enableProgressBar = true;
      this.showUploadConfirmationModal = true;
      if (this.loggedInUser.userPreferences?.datafileInputObject) {
        this.datafileInputObject = JSON.parse(
          this.loggedInUser.userPreferences.datafileInputObject
        );
      }
      if (this.loggedInUser.userPreferences?.selectedColumns) {
        this.selectedColumns = JSON.parse(
          this.loggedInUser.userPreferences.selectedColumns
        );
      }
      if (this.loggedInUser.userPreferences?.creditAmount) {
        this.creditAmountReturn = JSON.parse(
          this.loggedInUser.userPreferences.creditAmount
        );
      }
      this.setDataToLocalStorage();
    } else {
      // get data from localstorgae
      const selectedColumnsFromLocalStorage = JSON.parse(
        localStorage.getItem("selectedColumns")
      );
      if (selectedColumnsFromLocalStorage) {
        this.selectedColumns = selectedColumnsFromLocalStorage;
      }

      const datafileInputFromLocalStorage = JSON.parse(
        localStorage.getItem("datafileInputObject")
      );
      if (datafileInputFromLocalStorage) {
        this.datafileInputObject = datafileInputFromLocalStorage;
      }
    }
    if (this.selectedColumns && this.datafileInputObject) {
      this.calculateNumberOfDatapoints();
      if (!this.creditAmountReturn?.initialized) {
        await this.calculateCredits();
      }
      // await this.getQualityScoreInfo();
      this.isCreditsLoading = false;
    }
  },
  computed: {
    items() {
      var items = [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: this.$t("uploadProcessStep1.pageTitle"),
          href: "/upload-dataset/step1"
        },
        {
          text: this.$t("uploadProcessStep2.pageTitle"),
          href: "/upload-dataset/step2"
        },
        {
          text: this.$t("uploadProcessStep3.pageTitle"),
          active: true
        }
      ];
      return items;
    },
    localeFormat() {
      return "de-DE";
    }
  },
  methods: {
    setDataToLocalStorage() {
      localStorage.setItem(
        "datafileInputObject",
        JSON.stringify(this.datafileInputObject)
      );
      localStorage.setItem(
        "selectedColumns",
        JSON.stringify(this.selectedColumns)
      );
    },
    calculateNumberOfDatapoints() {
      for (const column of this.selectedColumns) {
        if (column.columnType === "PROJECTIVE_QUESTION") {
          this.numberOfDatapoints++;
        }
        if (column.columnType === "TIMESTAMP") {
          this.numberOfDatapoints++;
          if (column.timeStampsList.length > 0) {
            column.timeStampsList.forEach(() => {
              this.numberOfDatapoints++;
            });
          }
        }
        if (column.columnType === "OPEN_ENDED") {
          this.numberOfDatapoints++;
          if (column.openEndedList.length > 0) {
            column.openEndedList.forEach(() => {
              this.numberOfDatapoints++;
            });
          }
        }
        if (column.columnType === "ITEMBATTERY") {
          this.numberOfDatapoints++;
          if(column.itemBatteryRanges.length > 0) {
            column.itemBatteryRanges.forEach(() => {
              this.numberOfDatapoints++;
            });
          }
        }
      }
    },
    clickOnBack() {
      this.$router.push("/upload-dataset/step2");
    },
    async clickOnNextStep() {
      // 1. Remove datafileInputObject from localStorage
      // 2. Check Wallet Balance if having enogh credit or not
      // 3. If not enogh showNotEnoughCreditsModel else showConfirmationModel

      let loggedInUserCompany = await this.getLoggedInUserCompany(
        this.loggedInUser.company.id
      );

      if (
        this.datafileInputObject.amountOfCredits <=
          loggedInUserCompany.wallet.balance ||
        this.loggedInUser.accessLevel === AccessLevels.SUPER_ADMIN ||
        loggedInUserCompany.contract.name === "Unlimited"
      ) {
        this.showUploadConfirmationModal = true;
      } else {
        this.showNotEnoughCreditsModal = true;
      }
    },
    async getLoggedUser() {
      let userLoggedIn = await AuthService.getLoggedInUser();

      if (userLoggedIn) {
        this.loggedInUser = userLoggedIn;
      }
    },
    async calculateCredits() {
      // set Quality Score Count
      for (const column of this.selectedColumns) {
        if (column.columnType === "PROJECTIVE_QUESTION") {
          if (column.qualityScores.IS) this.selectedQualityScoreCounts.IS += 1;
          if (column.qualityScores.PS) this.selectedQualityScoreCounts.PS += 1;
          if (column.qualityScores.SDS)
            this.selectedQualityScoreCounts.SDS += 1;
          if (column.qualityScores.CS) this.selectedQualityScoreCounts.CS += 1;
        }

        if (column.columnType === "TIMESTAMP") {
          this.selectedQualityScoreCounts.TS += 1;
          this.selectedQualityScoreCounts.TS += column.timeStampsList.length;
        }

        if (column.columnType === "OPEN_ENDED") {
          this.selectedQualityScoreCounts.OES += 1;
          this.selectedQualityScoreCounts.OES += column.openEndedList.length;
        }

        if (column.columnType === "ITEMBATTERY") {
          this.selectedQualityScoreCounts.IBS +=
            column.itemBatteryColumnNames.length;

          this.selectedIBSCount += 1;

          if (column.itemBatteryRanges.length > 0) {
            for (let i = 0; i < column.itemBatteryRanges.length; i++) {
              this.selectedQualityScoreCounts.IBS +=
                column.itemBatteryRanges[i].columnNames.length;
            }
          }
        }
      }

      for (const qualityScore of Object.keys(this.selectedQualityScoreCounts)) {
        if (this.selectedQualityScoreCounts[qualityScore] > 0) this.numberOfQualityScores++;
        // if (this.selectedIBSCount > 0) this.numberOfQualityScores++;
      }

      const creditMetadata = {
        datafileKey: this.datafileInputObject.datafileKey,
        headerRow: this.datafileInputObject.metadata.headerRow,
        dataRecordStartRow:
          this.datafileInputObject.metadata.dataRecordStartRow,
          sheetName: this.datafileInputObject.metadata.sheetName,
        dataPointMetadata: this.datafileInputObject.metadata.dataPointMetadata,
        datarecordIdColumn:
          this.datafileInputObject.metadata.dataRecordIdColumn,
        missingValues: this.datafileInputObject.metadata.missingValues
      };

      const creditAmountReturn = await UploadService.getCreditAmount(
        this.selectedQualityScoreCounts,
        creditMetadata,
        this.datafileInputObject.datafileKey
      );

      if (creditAmountReturn.maximumCreditAmount) {
        this.creditAmountReturn.TS =
          creditAmountReturn.qualityScoreCredits.find(
            ({ qualityScore }) => qualityScore === "TS"
          );
        this.creditAmountReturn.OES =
          creditAmountReturn.qualityScoreCredits.find(
            ({ qualityScore }) => qualityScore === "OES"
          );
        this.creditAmountReturn.PQ =
          creditAmountReturn.qualityScoreCredits.find(
            ({ qualityScore }) => qualityScore === "PQ"
          );
        this.creditAmountReturn.IBS =
          creditAmountReturn.qualityScoreCredits.find(
            ({ qualityScore }) => qualityScore === "IBS"
          );

        this.creditAmountReturn.difference = creditAmountReturn.difference;
        this.creditAmountReturn.initialized = true;

        this.datafileInputObject.amountOfCredits =
          creditAmountReturn.maximumCreditAmount;
      }
    },
    async getLoggedInUserCompany(companyId) {
      let company = await CompanyService.getCompanyById(companyId);
      return company;
    },
    propoverMethodCreditDifference() {
      if (this.creditAmountReturn.difference > 0) {
        return this.$i18n.t(
          "uploadProcessStep3.pricingCard.table.differencePlus.description"
        );
      } else {
        return this.$i18n.t(
          "uploadProcessStep3.pricingCard.table.differenceMinus.description"
        );
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('uploadProcessStep3.pageTitle')" :items="items" />
    <div class="row">
      <div class="col-md-5">
        <!-- Datfile Metadata -->
        <b-card no-body>
          <div class="row p-3">
            <!-- Header -->
            <div class="col-md-12">
              <h5 class="card-title">{{ this.datafileInputObject?.title }}</h5>
              <hr />
            </div>

            <div class="col-md-12 mt-3">
              <!-- Quality Mark -->
              <div
                class="row"
                v-if="
                  this.datafileInputObject?.eligibleCertificateLevel ||
                  this.datafileInputObject?.certificateLevel
                "
              >
                <div class="col-md-7">
                  <label>
                    {{ $t("uploadProcessStep3.metaDataCard.certificateLevel") }}
                  </label>
                </div>
                <div class="col-md-5">
                  <div class="badge badge-gold">
                    {{
                      this.datafileInputObject?.eligibleCertificateLevel ||
                      this.datafileInputObject?.certificateLevel
                    }}
                  </div>
                </div>
              </div>

              <!-- number of Recods -->
              <div class="row">
                <div class="col-md-7">
                  <label>
                    {{
                      $t("uploadProcessStep3.metaDataCard.numberOfDataRecords")
                    }}
                  </label>
                </div>
                <div class="col-md-5">
                  {{ this.datafileInputObject?.numberOfRespondents }}
                </div>
              </div>

              <!-- Number of Quality Scores -->
              <div class="row">
                <div class="col-md-7">
                  <label>
                    {{
                      $t(
                        "uploadProcessStep3.metaDataCard.numberOfQualityScores"
                      )
                    }}
                  </label>
                </div>
                <div class="col-md-5">
                  {{
                    this.numberOfQualityScores
                  }}
                </div>
              </div>

              <!-- Number of Datapoints -->
              <div class="row">
                <div class="col-md-7">
                  <label>
                    {{
                      $t("uploadProcessStep3.metaDataCard.numberOfDatapoints")
                    }}
                  </label>
                </div>
                <div class="col-md-5">
                  {{ this.numberOfDatapoints }}
                </div>
              </div>
            </div>
          </div>
        </b-card>

        <!-- Pricing -->
        <div class="card" v-if="this.datafileInputObject?.amountOfCredits">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h5>
                  <i class="bx bx-money h4 text-warning bx-tada mr-1"></i>
                  {{ $t("uploadProcessStep3.pricingCard.title") }}
                </h5>
              </div>
              <div class="col-md-6 text-right">
                <a
                  href="https://help.redem.io/docs/data-import/redem-credits/"
                  target="_blank"
                  >{{ $t("uploadProcessStep3.pricingCard.learnMore") }}
                  <i class="bx bx-link-external ml-1"></i
                ></a>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                {{ $t("uploadProcessStep3.pricingCard.description") }}
              </div>
              <div class="col-md-12">
                {{ $t("uploadProcessStep3.pricingCard.note") }}
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12">
                <ul>
                  <li>{{ $t("uploadProcessStep3.pricingCard.point1") }}</li>
                  <li>{{ $t("uploadProcessStep3.pricingCard.point2") }}</li>
                </ul>
              </div>
            </div>
            <div class="table-responsive mt-4">
              <table class="table">
                <thead>
                  <tr>
                    <th>
                      {{
                        $t(
                          "uploadProcessStep3.pricingCard.table.header.description"
                        )
                      }}
                    </th>
                    <th>
                      {{
                        $t("uploadProcessStep3.pricingCard.table.header.amount")
                      }}
                    </th>
                    <th class="text-right">
                      {{
                        $t(
                          "uploadProcessStep3.pricingCard.table.header.credits"
                        )
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- OES Amount -->
                  <tr v-if="this.creditAmountReturn.OES.credits">
                    <td>OES</td>
                    <td>
                      {{ this.creditAmountReturn.OES.numberOfDataPoints }}
                    </td>

                    <td class="text-right">
                      {{
                        this.creditAmountReturn.OES.credits.toLocaleString(
                          localeFormat,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        )
                      }}
                    </td>
                  </tr>

                  <!-- TS Amount -->
                  <tr v-if="this.creditAmountReturn.TS.credits">
                    <td>TS</td>
                    <td>
                      {{ this.creditAmountReturn.TS.numberOfDataPoints }}
                    </td>

                    <td class="text-right">
                      {{
                        this.creditAmountReturn.TS.credits.toLocaleString(
                          localeFormat,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        )
                      }}
                    </td>
                  </tr>

                  <!-- IBS Amount -->
                  <tr v-if="this.creditAmountReturn.IBS.credits">
                    <td>GQS Items</td>
                    <td>
                      {{ this.creditAmountReturn.IBS.numberOfDataPoints }}
                    </td>
                    <td class="text-right">
                      <span>
                        {{
                          this.creditAmountReturn.IBS.credits.toLocaleString(
                            localeFormat,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          )
                        }}
                      </span>
                    </td>
                  </tr>

                  <!-- PQ Amount -->
                  <tr v-if="this.creditAmountReturn.PQ.credits">
                    <td>Projective Question</td>
                    <td>
                      {{ this.creditAmountReturn.PQ.numberOfDataPoints }}
                    </td>
                    <td class="text-right">
                      {{
                        this.creditAmountReturn.PQ.credits.toLocaleString(
                          localeFormat,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        )
                      }}
                    </td>
                  </tr>

                  <tr v-if="this.creditAmountReturn.difference">
                    <td>
                      {{
                        this.creditAmountReturn.difference > 0
                          ? $t(
                              "uploadProcessStep3.pricingCard.table.differencePlus.label"
                            )
                          : $t(
                              "uploadProcessStep3.pricingCard.table.differenceMinus.label"
                            )
                      }}
                      <span>
                        <i
                          v-b-popover.hover="propoverMethodCreditDifference"
                          class="ml-2 bx bxs-info-circle text-info"
                          style="cursor: pointer"
                        ></i>
                      </span>
                    </td>
                    <td></td>
                    <td class="text-right">
                      {{
                        this.creditAmountReturn.difference.toLocaleString(
                          localeFormat,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        )
                      }}
                    </td>
                  </tr>

                  <!-- Total -->
                  <tr>
                    <td colspan="2" class="text-left">
                      <strong>
                        {{
                          $t("uploadProcessStep3.pricingCard.table.total.title")
                        }}
                      </strong>
                    </td>
                    <td class="text-right">
                      <h5 class="m-0">
                        {{
                          this.datafileInputObject?.amountOfCredits.toLocaleString(
                            localeFormat,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          )
                        }}
                      </h5>
                    </td>
                  </tr>

                  <!-- Average per Respondent -->
                  <tr>
                    <td colspan="2" class="text-left">
                      <strong>
                        {{
                          $t(
                            "uploadProcessStep3.pricingCard.table.perRespTotal"
                          )
                        }}
                      </strong>
                    </td>
                    <td class="text-right">
                      <h5 class="m-0">
                        {{
                          (
                            this.datafileInputObject?.amountOfCredits /
                            this.datafileInputObject?.numberOfRespondents
                          ).toLocaleString(localeFormat, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        }}
                      </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card" v-if="this.isCreditsLoading">
          <!-- Loading -->
          <div class="col-12 text-center p-5">
            <b-spinner
              style="width: 5rem; height: 5rem"
              class="m-2"
              variant="mid"
              role="status"
            ></b-spinner>
          </div>
        </div>
      </div>

      <!-- Selected Columns -->
      <div class="col-md-7">
        <div class="card">
          <div class="card-body">
            <h5>
              <i class="bx bx-detail h4 text-info bx-tada mr-1"></i>
              {{ $t("uploadProcessStep3.summaryCard.title") }}
            </h5>

            <div
              class="ml-2 mr-2 mt-5"
              v-for="(column, columnIndex) in this.selectedColumns"
              :key="columnIndex"
            >
              <!-- Column Type -->
              <div>
                <b-badge style="font-size: 12px" variant="primary">
                  <span v-if="column.columnType === 'PROJECTIVE_QUESTION'">
                    {{
                      $t(
                        "uploadProcessStep3.summaryCard.columnTypes.projectiveQuestion"
                      )
                    }}
                  </span>
                  <span v-if="column.columnType === 'RESPONDENT_ID'">
                    {{
                      $t(
                        "uploadProcessStep3.summaryCard.columnTypes.dataRecordID"
                      )
                    }}
                  </span>
                  <span v-if="column.columnType === 'TIMESTAMP'">
                    {{
                      $t("uploadProcessStep3.summaryCard.columnTypes.timeStamp")
                    }}
                  </span>
                  <span v-if="column.columnType === 'OPEN_ENDED'">
                    {{
                      $t("uploadProcessStep3.summaryCard.columnTypes.openEnded")
                    }}
                  </span>
                  <span v-if="column.columnType === 'ITEMBATTERY'">
                    {{
                      $t(
                        "uploadProcessStep3.summaryCard.columnTypes.itemBattery"
                      )
                    }}
                  </span>
                </b-badge>
              </div>

              <!-- Duplicate & Context Check -->
              <div class="mt-3" v-if="column.columnType === 'OPEN_ENDED'">
                <div class="row">
                  <!-- Duplicate Check -->
                  <div class="col-md-12">
                    <label>
                      {{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.OES.duplicateCheck"
                        )
                      }}:
                    </label>
                    <span class="ml-2" v-if="datafileInputObject.enableDuplicateChecks">
                      <b-badge style="font-size: 12px" variant="info">
                        {{
                          $t(
                            "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.yes"
                          )
                        }}
                      </b-badge>
                    </span>
                    <span class="ml-2" v-if="!datafileInputObject.enableDuplicateChecks">
                      <b-badge style="font-size: 12px" variant="light">
                        {{
                          $t(
                            "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.no"
                          )
                        }}
                      </b-badge>
                    </span>
                  </div>

                  <!-- Contect Check -->
                  <div class="col-md-12">
                    <label>
                      {{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.label"
                        )
                      }}:
                    </label>
                    <span class="ml-2" v-if="datafileInputObject.isEnableWrongTopicCheck">
                      <b-badge style="font-size: 12px" variant="info">
                        {{
                          $t(
                            "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.yes"
                          )
                        }}
                      </b-badge>
                    </span>
                    <span class="ml-2" v-if="!datafileInputObject.isEnableWrongTopicCheck">
                      <b-badge style="font-size: 12px" variant="light">
                        {{
                          $t(
                            "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.no"
                          )
                        }}
                      </b-badge>
                    </span>
                  </div>
                </div>         
              </div>


              <!--  Respondent Id Column -->
              <div
                class="col-12 mt-3 p-3"
                v-if="column.columnType === 'RESPONDENT_ID'"
                style="border: 1px solid #e3e3e3"
              >
                <div class="row">
                  <div class="col-md-3">
                    <label>
                      {{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.respondentId"
                        )
                      }}:
                    </label>
                  </div>
                  <div class="col-md-9">
                    {{ column.datasetColumnName }}
                  </div>
                </div>
              </div>

              <!-- OES Main Data Points -->
              <div
                class="col-12 mt-3 p-3"
                v-if="column.columnType === 'OPEN_ENDED'"
                style="border: 1px solid #e3e3e3"
              >
                <div class="row mb-2">
                  <div class="col-md-3">
                    <label
                      >{{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.OES.dataPointIdetifer"
                        )
                      }}:
                    </label>
                  </div>
                  <div class="col-md-9">
                    {{ column.datasetColumnName }}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-3">
                    <label
                      >{{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.OES.questionTitle"
                        )
                      }}:
                    </label>
                  </div>
                  <div class="col-md-9">
                    {{ column.questionTitle }}
                  </div>
                </div>
                <div
                  class="row mb-2"
                  v-if="
                    column.keywords.length > 0 &&
                    datafileInputObject.isEnableWrongTopicCheck
                  "
                >
                  <div class="col-md-3">
                    <label
                      >{{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.OES.keywords"
                        )
                      }}:
                    </label>
                  </div>
                  <div class="col-md-9">
                    <span
                      v-for="(keyword, keywordIndex) in column.keywords"
                      :key="keywordIndex"
                    >
                      <b-badge
                        style="font-size: 12px"
                        variant="success"
                        class="mr-2 mb-1"
                      >
                        {{ keyword.text }}
                      </b-badge>
                    </span>
                  </div>
                </div>
                <div
                  class="row mb-2"
                  v-if="
                    column.languagesToCheck &&
                    column.languagesToCheck.length > 0
                  "
                >
                  <div class="col-md-3">
                    <label
                      >{{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.OES.languages"
                        )
                      }}:
                    </label>
                  </div>
                  <div class="col-md-9">
                    <span
                      v-for="(
                        language, languageIndex
                      ) in column.languagesToCheck"
                      :key="languageIndex"
                    >
                      <b-badge
                        style="font-size: 12px"
                        variant="primary"
                        class="mr-2 mb-1"
                      >
                        {{ language.code }} - {{ language.name }}
                      </b-badge>
                    </span>
                  </div>
                </div>
              </div>

              <!-- OES Additional Data Points  -->
              <div
                v-if="
                  column.columnType === 'OPEN_ENDED' &&
                  column.openEndedList.length > 0
                "
              >
                <div
                  class="col-12 mt-3 p-3"
                  v-for="(
                    oesDataPoint, oesDataPointIndex
                  ) in column.openEndedList"
                  :key="oesDataPointIndex"
                  style="border: 1px solid #e3e3e3"
                >
                  <div class="row mb-2">
                    <div class="col-md-3">
                      <label
                        >{{
                          $t(
                            "uploadProcessStep3.summaryCard.columnDescription.OES.dataPointIdetifer"
                          )
                        }}:
                      </label>
                    </div>
                    <div class="col-md-9">
                      {{ oesDataPoint.datasetColumnName }}
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-md-3">
                      <label
                        >{{
                          $t(
                            "uploadProcessStep3.summaryCard.columnDescription.OES.questionTitle"
                          )
                        }}:
                      </label>
                    </div>
                    <div class="col-md-9">
                      {{ oesDataPoint.questionTitle }}
                    </div>
                  </div>
                  <div
                    class="row mb-2"
                    v-if="
                      oesDataPoint.keywords.length > 0 &&
                      datafileInputObject.isEnableWrongTopicCheck
                    "
                  >
                    <div class="col-md-3">
                      <label
                        >{{
                          $t(
                            "uploadProcessStep3.summaryCard.columnDescription.OES.keywords"
                          )
                        }}:
                      </label>
                    </div>
                    <div class="col-md-9">
                      <span
                        v-for="(keyword, keywordIndex) in oesDataPoint.keywords"
                        :key="keywordIndex"
                      >
                        <b-badge
                          style="font-size: 12px"
                          variant="success"
                          class="mr-2 mb-1"
                        >
                          {{ keyword.text }}
                        </b-badge>
                      </span>
                    </div>
                  </div>
                  <div
                    class="row mb-2"
                    v-if="
                      oesDataPoint.languagesToCheck &&
                      oesDataPoint.languagesToCheck.length > 0
                    "
                  >
                    <div class="col-md-3">
                      <label
                        >{{
                          $t(
                            "uploadProcessStep3.summaryCard.columnDescription.OES.languages"
                          )
                        }}:
                      </label>
                    </div>
                    <div class="col-md-9">
                      <span
                        v-for="(
                          language, languageIndex
                        ) in column.languagesToCheck"
                        :key="languageIndex"
                      >
                        <b-badge
                          style="font-size: 12px"
                          variant="primary"
                          class="mr-2 mb-1"
                        >
                          {{ language.code }} - {{ language.name }}
                        </b-badge>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Time Stamps -->
              <div
                class="col-12 mt-3 p-3"
                v-if="column.columnType === 'TIMESTAMP'"
                style="border: 1px solid #e3e3e3"
              >
                <div class="row mb-2">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.TS.mainTimeStamp"
                        )
                      }}:
                    </label>
                  </div>
                  <div class="col-md-8">
                    {{ column.datasetColumnName }}
                  </div>
                </div>
                <div
                  class="row mb-2"
                  v-if="
                    column.timeStampsList && column.timeStampsList.length > 0
                  "
                >
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.TS.aditionalTimeStamps"
                        )
                      }}:
                    </label>
                  </div>
                  <div class="col-md-8">
                    <div
                      v-for="(
                        timeStamp, timeStampIndex
                      ) in column.timeStampsList"
                      :key="timeStampIndex"
                    >
                      {{ timeStamp.datasetColumnName }}
                    </div>
                  </div>
                </div>
                <div class="row" v-if="column.groupSelector.length > 0">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.TS.groupSelection"
                        )
                      }}:
                    </label>
                  </div>
                  <div class="col-md-8">
                    <span
                      v-for="(
                        groupSelector, groupSelectorIndex
                      ) in column.groupSelector"
                      :key="groupSelectorIndex"
                      class="mr-2"
                    >
                      {{ groupSelector.datasetColumnName }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- Projective Question Set -->
              <div
                class="col-12 mt-3 p-3"
                v-if="column.columnType === 'PROJECTIVE_QUESTION'"
                style="border: 1px solid #e3e3e3"
              >
                <div class="row mb-2">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.PQ.dataPointIdetifer"
                        )
                      }}:
                    </label>
                  </div>
                  <div class="col-md-8">
                    {{ column.datasetColumnName }}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.PQ.predictionColumns"
                        )
                      }}:
                    </label>
                  </div>
                  <div class="col-md-8">
                    <div
                      v-for="(
                        predictionColumn, predictionColumnIndex
                      ) in column.predictionsList"
                      :key="predictionColumnIndex"
                      class="mr-2 mb-2"
                    >
                      {{ predictionColumn.datasetColumnName }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.PQ.selectedQualityScore"
                        )
                      }}:
                    </label>
                  </div>
                  <div class="col-md-8">
                    <span v-if="column.qualityScores.PS">
                      <b-badge
                        style="font-size: 12px"
                        variant="primary"
                        class="mr-2 mb-1"
                      >
                        PS
                      </b-badge>
                    </span>
                    <span v-if="column.qualityScores.CS">
                      <b-badge
                        style="font-size: 12px"
                        variant="primary"
                        class="mr-2 mb-1"
                      >
                        CS
                      </b-badge>
                    </span>
                    <span v-if="column.qualityScores.SDS">
                      <b-badge
                        style="font-size: 12px"
                        variant="primary"
                        class="mr-2 mb-1"
                      >
                        SDS
                      </b-badge>
                    </span>
                    <span v-if="column.qualityScores.IS">
                      <b-badge
                        style="font-size: 12px"
                        variant="primary"
                        class="mr-2 mb-1"
                      >
                        IS
                      </b-badge>
                    </span>
                  </div>
                </div>
              </div>

              <!-- Item Battery -->
              <div
                class="col-12 mt-3 p-3"
                v-if="column.columnType === 'ITEMBATTERY'"
                style="border: 1px solid #e3e3e3"
              >
                <!-- Main GQS -->
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.IBS.mainItemBattary"
                        )
                      }}:
                    </label>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-12">
                        {{ column.datasetColumnName }}
                        <i
                          class="bx bx-arrow-back bx-flip-horizontal ml-3 mr-3"
                        ></i>
                        {{ column.itemBatteryEndRangeName }}
                        <span class="ml-2">
                          ({{ column.itemBatteryColumnNames.length }})
                        </span>
                      </div>
                      <div class="col-md-12 mt-1">
                        <label>
                          {{
                            $t(
                              "uploadProcessStep3.summaryCard.columnDescription.IBS.patternCheckActivated"
                            )
                          }}:
                        </label>
                        <span class="ml-2" v-if="datafileInputObject.enablePatternCheckMain">
                          <b-badge style="font-size: 12px" variant="info">
                            {{
                              $t(
                                "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.yes"
                              )
                            }}
                          </b-badge>
                        </span>
                        <span class="ml-2" v-if="!datafileInputObject.enablePatternCheckMain">
                          <b-badge style="font-size: 12px" variant="light">
                            {{ $t( "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.no" ) }}
                          </b-badge>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Additional GQS -->   
                <div
                  class="row mb-2"
                  v-if="column.itemBatteryRanges.length > 0"
                >
                  <div class="col-md-4">
                    <label>
                      {{
                        $t(
                          "uploadProcessStep3.summaryCard.columnDescription.IBS.aditionalItemBattary"
                        )
                      }}:
                    </label>
                  </div>
                  <div class="col-md-8">
                    <div
                      v-for="(
                        itemRange, itemRangeIndex
                      ) in column.itemBatteryRanges"
                      :key="itemRangeIndex"
                    >
                      <div class="row mb-3">
                        <div class="col-md-12">
                          {{ itemRange.rangeStartName }}
                          <i
                            class="bx bx-arrow-back bx-flip-horizontal ml-3 mr-3"
                          ></i>
                          {{ itemRange.rangeEndName }}
                          <span class="ml-2">
                            ({{ itemRange.columnNames.length }})
                          </span>
                        </div>

                        <div class="col-md-12 mt-1">
                          <label>
                            {{
                              $t(
                                "uploadProcessStep3.summaryCard.columnDescription.IBS.patternCheckActivated"
                              )
                            }}:
                          </label>
                          <span class="ml-2" v-if="datafileInputObject.enablePatternCheckAdditional[itemRangeIndex]">
                            <b-badge style="font-size: 12px" variant="info">
                              {{ $t( "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.yes" ) }}
                            </b-badge>
                          </span>
                          <span class="ml-2" v-if="!datafileInputObject.enablePatternCheckAdditional[itemRangeIndex]">
                            <b-badge style="font-size: 12px" variant="light">
                              {{ $t( "uploadProcessStep3.summaryCard.columnDescription.OES.contextCheck.no" ) }}
                            </b-badge>
                          </span>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Button Container-->
    <div
      class="fixed-bottom static-buttons"
      v-bind:class="[isExpanded ? 'expanded' : 'collapsed']"
    >
      <div class="card-body col-md-12 row">
        <div class="col-md-6 text-left">
          <b-button variant="primary" @click="clickOnBack()" class="mr-2">
            <i class="mdi mdi-chevron-left"></i>
            {{ $t("uploadProcessStep3.general.backButton") }}
          </b-button>
        </div>
        <div class="col-md-6 text-right">
          <b-button
            variant="success"
            @click="clickOnNextStep()"
            class="mr-2"
            :disabled="isCreditsLoading"
          >
            {{ $t("uploadProcessStep3.general.finishButton") }}
            <i class="mdi mdi-check"></i>
          </b-button>
        </div>
      </div>
    </div>

    <!-- Confirmation Datafile Upload modal -->
    <UploadDatafileConfirmationModal
      :loggedInUser="this.loggedInUser"
      v-if="showUploadConfirmationModal === true"
      :visible="showUploadConfirmationModal"
      @close="showUploadConfirmationModal = false"
      :datafileInputObject="this.datafileInputObject"
      :selectedColumns="this.selectedColumns"
      :creditAmountReturn="this.creditAmountReturn"
      :enableProgressBar="this.enableProgressBar"
    />

    <!-- Not Enough Credits To Upload Model -->
    <NotEnoughCreditsToUploadModal
      v-if="showNotEnoughCreditsModal === true"
      :visible="showNotEnoughCreditsModal"
      @close="showNotEnoughCreditsModal = false"
    />
  </Layout>
</template>
<style scoped>
.static-buttons {
  z-index: 1;
  margin: 0;
  background-color: white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.collapsed {
  padding-left: 75px;
}

.expanded {
  padding-left: 250px;
}
</style>
