<script>
export default {
  props: {
    visible: Boolean,
    closeable: Boolean
  },
  data() {
    return {};
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  mounted() {},
  methods: {
    goToDashboard() {
      this.$router.push("/");
    }
  }
};
</script>

<template>
  <!-- Not Enough Credits to Upload Modal-->
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="row text-center">
      <div class="col-lg-12 mt-3">
        <img
          src="@/assets/images/red-warning.png"
          alt
          class="img-fluid mb-4 mt-3"
          style="width: 100px"
        />
        <h5>{{ $t("uploadProcessStep3.notEnoughCreditsPopup.title") }}</h5>
      </div>

      <div class="col-lg-12 mt-3">
        <p>
          {{ $t("uploadProcessStep3.notEnoughCreditsPopup.text") }}
        </p>
      </div>

      <div class="p-4 col-md-12 text-lg-center">
        <b-button variant="light" @click="showModal = false">{{
          $t("uploadProcessStep3.notEnoughCreditsPopup.button.cancel")
        }}</b-button>
        <b-button variant="warning" @click="goToDashboard()" class="ml-2">{{
          $t("uploadProcessStep3.notEnoughCreditsPopup.button.backToDashboard")
        }}</b-button>
      </div>
    </div>
  </b-modal>
</template>
